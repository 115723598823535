let checkUser = () => {
  let userDetails = JSON.parse(localStorage.getItem("personalpac-user"));
  if (userDetails) {
    return true;
  } else {
    return false;
  }
};

exports.baseURL = process.env.VUE_APP_API_URL;
exports.verNum = process.env.VUE_APP_VERSION_NUMBER;
exports.token = process.env.VUE_APP_TOKEN;
exports.checkUser = checkUser;
