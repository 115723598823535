import axios from "axios";
import Config from "./config.js";

var baseURL = Config.baseURL;

let instanceToken = axios.create({
  baseURL: baseURL,
});

let instanceFileUpload = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const instance = axios.create({
  baseURL: baseURL,
});

export default {
  instance: instance,
  instanceToken: instanceToken,
  instanceFileUpload: instanceFileUpload,
};
